<template>
  <el-card shadow="never">
    <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="文章编号" prop="id" v-if="false">
        <el-input v-model="ruleForm.id" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title" autocomplete="off"></el-input>
        <!-- :disabled="!(ruleForm.status==0||ruleForm.status==1||ruleForm.status==2)" -->
      </el-form-item>
      <el-form-item label="关键词" prop="keyword">
        <el-input v-model.number="ruleForm.keyword"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input v-model.number="ruleForm.description"></el-input>
      </el-form-item>
      <el-form-item label="正文" prop="content">
        <div id="edtor"></div>
      </el-form-item>
      <el-form-item label="文章类别" prop="typeId">
        <el-select v-model="ruleForm.typeId" placeholder="请选择">
          <!-- :disabled="!(ruleForm.status==1)" -->
          <el-option
              v-for="item in bigOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属客户" prop="customerId">
        <el-select v-model="ruleForm.customerId" placeholder="请选择">
          <el-option
              v-for="item in customerOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button  type="primary"  @click="editArticle()">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import wangeditor from 'wangeditor'
import {baseURL} from '@/utils/ajax.js';

export default {

  data() {
    return {
      editor: null,
      customerOptions: [],
      bigOptions: [],
      ruleForm: {
        id: 0,
        title: '',
        keyword: '',
        description: '',
        content: '',
        customerId: 0,
        typeId: 0,
        status: 0
      },
    }
  },
  mounted() {
    const editor = new wangeditor('#edtor');
    editor.config.onchange = function (newHtml) {
      console.log('change 之后最新的 html', newHtml)
    }
    // editor.config.uploadImgServer = '/api/luyao/img/upload'
    // editor.config.uploadFileName = 'picture'
    // editor.config.uploadImgShowBase64 = true
     editor.config.uploadImgServer = baseURL+"/img/upload";
    editor.config.uploadFileName = "picture";
   
    (editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        console.log("customInsert", result);
        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        insertImgFn(baseURL.replace("/luyao","")+result.data.data[0]);
      },
    });
    editor.create();
    this.editor = editor;
    this.init();
    this.getBigTypeList();
    this.getCustomerList();
  },
  methods: {
    getCustomerList() {
      this.$api.get(
          "/customer/list",
          null,
          {
            list: false
          },
          (successRes) => {
            this.customerOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getBigTypeList() {
      this.$api.get(
          "/type/big/list",
          null,
          {
            list: false
          },
          (successRes) => {
            this.bigOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    editArticle() {
      let context = this.editor.txt.html();
      this.$api.post('/article/update', null,
          {
            "content": context,
            "title": this.ruleForm.title,
            "id": this.ruleForm.id,
            "keyword": this.ruleForm.keyword,
            "description": this.ruleForm.description,
            "customerId": this.ruleForm.customerId,
            "typeId": this.ruleForm.typeId,
            "audit":true
          },
          (successRes) => {
            if (successRes.status == 200) {
              this.$message('操作成功');
              this.$router.push({name: "examineList"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          })
    },
    init() {
      const articleId = this.$route.query.id;
      this.getArticleById(articleId);
    },
    getArticleById(id) {
      this.$api.get(
          "/article/getById",
          null,
          {id: id},
          (successRes) => {
            console.log(successRes);
            this.ruleForm = successRes.data;
            this.editor.txt.html(this.ruleForm.content)
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
  },
}
</script>
<style>
.w-e-menu{
  z-index: 2 !important;
}
.w-e-text-container{
  z-index: 1 !important;
}
</style>